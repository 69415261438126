import { config } from "@/components/providers/data";
import { useTheme } from "next-themes";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

const Logo = ({ src }) => {
  // Destructuring items from the config object
  const { logo, logo_width, logo_height, logo_text, title, logo_darkmode } =
    config.site;

  const { theme, resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const resolvedLogo =
    mounted && (theme === "dark" || resolvedTheme === "dark")
      ? logo_darkmode
      : logo;
  const logoPath = src ? src : resolvedLogo;

  return (
    <Link href="/" className="navbar-brand inline-block">
      {logoPath ? (
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={
              theme === "dark"
                ? "/images/logo-darkmode-786.webp"
                : "/images/logo-786.webp"
            }
          />
          <Image
            width={parseInt(logo_width.replace("px", ""))}
            height={parseInt(logo_height.replace("px", ""))}
            src={logoPath}
            alt={title}
            priority
            style={{
              height: logo_height,
              width: logo_width,
            }}
          />
        </picture>
      ) : logo_text ? (
        logo_text
      ) : (
        title
      )}
    </Link>
  );
};

export default Logo;
