import React from "react";

const socialIcons = {
  facebook: (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className="w-16 h-16 text-black dark:text-white"
    >
      <path
        fillRule="evenodd"
        d="M22.675 0H1.325C.594 0 0 .593 0 1.326v21.348C0 23.407.593 24 1.325 24h11.483v-9.294H9.746V11.41h3.062V8.82c0-3.028 1.845-4.675 4.539-4.675 1.293 0 2.402.096 2.726.139v3.166l-1.872.001c-1.468 0-1.75.698-1.75 1.72v2.257h3.5l-.455 3.296h-3.045V24h5.963c.73 0 1.324-.593 1.324-1.326V1.326C24 .593 23.407 0 22.675 0z"
        clipRule="evenodd"
      />
    </svg>
  ),
  twitter: (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className="w-16 h-16 text-black dark:text-white"
    >
      <path d="M24 4.557a9.807 9.807 0 01-2.828.775 4.932 4.932 0 002.165-2.724 9.865 9.865 0 01-3.127 1.194 4.916 4.916 0 00-8.384 4.482A13.933 13.933 0 011.671 3.149a4.905 4.905 0 001.523 6.553 4.906 4.906 0 01-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.934 4.934 0 01-2.224.085 4.917 4.917 0 004.588 3.417A9.867 9.867 0 010 21.543a13.917 13.917 0 007.548 2.212c9.057 0 14.01-7.502 14.01-14.01 0-.213-.005-.426-.014-.637A10.012 10.012 0 0024 4.557z" />
    </svg>
  ),
  yelp: (
    <svg
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      className="w-16 h-16 text-black dark:text-white"
    >
      <path d="M20.74 10.34c-.4-.08-1.33-.45-1.65-.55-.42-.14-.95-.16-1.34.16-.37.3-.49.81-.49 1.29 0 .7 0 1.44.12 2.14.1.54.41 1.3.99 1.3.25 0 .5-.13.7-.3.31-.3.48-.74.7-1.09.5-.84 1.13-1.62 1.61-2.51.36-.67.33-1.48-.14-1.74zM13.8 9.88c.29-.03.58-.1.87-.19.42-.15.85-.32 1.29-.46.69-.23 1.21-.6 1.65-1.09.61-.68.71-1.72.27-2.57-.52-.99-1.57-1.5-2.64-1.5-.52 0-1.09.12-1.62.38-1.06.5-2.07 1.22-3.06 1.88-.36.24-.77.45-1.15.72-.33.23-.57.63-.7 1.01-.12.35-.21.75-.16 1.11.09.64.46 1.14.97 1.39.3.14.63.18.94.18.35.02.68.02 1.02 0zm-5.3 2.65c-.2 0-.35.07-.49.2-.2.2-.34.51-.48.76-.44.75-.89 1.49-1.31 2.25-.26.47-.51.95-.68 1.47-.2.63-.12 1.35.27 1.91.48.72 1.31 1.03 2.07 1.03 1.02 0 1.91-.49 2.63-1.14.3-.27.64-.59.84-.95.16-.27.28-.6.35-.91.12-.46.18-.95.2-1.42 0-.49-.03-.99-.06-1.48 0-.44-.2-.91-.53-1.19-.34-.28-.8-.34-1.24-.34-.6.02-1.21.14-1.79.14zm-2.67-4.35c.13-.33.29-.65.41-.98.26-.7.59-1.36.83-2.06.14-.38.32-.78.5-1.13.31-.59.71-1.06 1.24-1.47.47-.36 1.01-.63 1.57-.86.69-.29 1.3-.67 1.87-1.11.63-.49.93-1.15 1.11-1.88.2-.81-.22-1.7-.9-2.15-.56-.39-1.33-.5-1.98-.36-.82.19-1.52.71-2.06 1.3-.57.63-1.03 1.36-1.5 2.07-.32.47-.66.93-.99 1.38-.5.68-1.15 1.29-1.67 2-.39.53-.61 1.17-.74 1.81-.08.41.11.84.45 1.08.31.21.68.21 1.02.26.48.08.95.18 1.43.26zm7.2 5.08c-.36-.08-.7-.23-1.06-.33-.64-.2-1.25-.55-1.92-.67-.41-.07-.81.09-1.14.34-.31.24-.54.59-.69.95-.2.48-.21 1.03-.25 1.54-.04.49-.04 1.01.13 1.48.16.42.5.76.93.92.72.25 1.56.17 2.17-.27.4-.3.77-.68 1.13-1.04.7-.71 1.35-1.45 2.05-2.16.33-.32.58-.72.78-1.13.13-.28.26-.56.35-.87.21-.71.12-1.61-.36-2.15-.44-.49-1.14-.51-1.69-.31z" />
    </svg>
  ),
  instagram: (
    <svg
      className="w-16 h-16 text-black dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
        clipRule="evenodd"
      />
    </svg>
  ),
};

const Social = ({ source, className }) => {
  return (
    <div className={className}>
      {source.map((social, index) => {
        const Icon = socialIcons[social.name.toLowerCase()];
        return (
          <a
            key={index}
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block p-2"
            aria-label={social.name || "Social Media Link"}
          >
            {Icon ? Icon : <span className="text-sm">Icon not found</span>}
          </a>
        );
      })}
    </div>
  );
};

export default Social;
