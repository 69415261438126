import Link from "next/link";
import { config } from "@/components/providers/data";
import React from "react";
import Logo from "./Logo";
import ThemeSwitcher from "../utilities/ThemeSwitcher";

const Header = () => {
  return (
    <header
      className={`header opacity-1 z-30 drop-shadow-xl ${
        config.settings.sticky_header ? "md:sticky md:top-0" : ""
      }`}
    >
      <nav className="container mx-auto flex items-center justify-between p-6 gap-4">
        <div className="flex items-center">
          <Logo />
        </div>
        <ThemeSwitcher className="mr-5" />
        <div className="text-xs  dark:text-white sm:text-sm px-5 leading-5 navContactInfo">
          <p>{config.site.address}</p>
          <a
            className="underline phone  dark:text-white  hover:text-blue-500"
            href="tel:(142) 373-27000"
          >
            + {config.site.phone}
          </a>
        </div>
        <div className="hidden md:flex md:gap-1 lg:items-center lg:space-x-8">
          {config.menu.main.map((item, index) => (
            <Link href={item.url} key={index}>
              <div className="btn btn-primary text-center">{item.name}</div>
            </Link>
          ))}
        </div>
      </nav>
      <div className="md:hidden">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center items-center space-x-4">
            {config.menu.main.map((item, index) => (
              <Link href={item.url} key={index}>
                <div className="btn btn-primary text-xs">{item.name}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
