import { config } from "@/components/providers/data";
import Link from "next/link";
import Logo from "./Logo";
import Social from "./Social";

const Footer = () => {
  const copyright = { __html: config.site.copyright };

  return (
    <footer className="bg-body dark:bg-darkmode-body">
      <div className="container">
        <div className="flex flex-col md:flex-row items-center justify-between py-7 md:py-14">
          <div className="mb-2 md:mb-8 text-center lg:mb-0 lg:text-left">
            <Logo />
          </div>
          <div className="mb-2 md:mb-8 text-center lg:w-2/4 lg:mb-0">
            <ul>
              {config.menu.footer.map((menu) => (
                <li
                  className="m-1 md:m-3 inline-block text-black dark:text-white"
                  key={menu.name}
                >
                  <Link href={menu.url}>{menu.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-2 md:mb-8 text-center lg:col-3 lg:mb-0 lg:mt-0 lg:text-right">
            <Social source={config.social} className="social-icons" />
          </div>
        </div>
      </div>
      <div className="border-t border-border py-7 dark:border-darkmode-border">
        <div className="container text-center text-dark dark:text-white">
          <p
            dangerouslySetInnerHTML={copyright}
            className="text-black dark:text-white"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
