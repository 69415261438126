import Footer from "@/components/partial/Footer";
import Header from "@/components/partial/Header";
import Providers from "@/components/providers/Providers";
import ResponsiveIndicator from "@/components/utilities/ResponsiveIndicator";
import "@/styles/globals.css";
import Head from "next/head";
import { config } from "@/components/providers/data";

export default function App({ Component, pageProps }) {
  const pageTitle = `${config.site.title} - Home`;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Providers>
        <ResponsiveIndicator />
        <Header />
        <Component {...pageProps} />
        <Footer />
      </Providers>
    </>
  );
}
